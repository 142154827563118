const ERROR_NAMESPACE = "massMailing:error."

export const CREATE_MASS_MAILING_FAILED_MESSAGE = `${ERROR_NAMESPACE}create`
export const START_MASS_MAILING_FAILED_MESSAGE = `${ERROR_NAMESPACE}start`
export const PAUSE_MASS_MAILING_FAILED_MESSAGE = `${ERROR_NAMESPACE}pause`
export const DELETE_MASS_MAILING_FAILED_MESSAGE = `${ERROR_NAMESPACE}delete`
export const ADD_MASS_MAILING_ITEMS_FAILED_MESSAGE = `${ERROR_NAMESPACE}add-items`
export const MAKE_MASS_MAILING_REPORT_FAILED_MESSAGE = `${ERROR_NAMESPACE}report`
export const LIST_MASS_MAILING_REPORTS_FAILED_MESSAGE = `${ERROR_NAMESPACE}reports`
export const SEARCH_MASS_MAILINGS_FAILED_MESSAGE = `${ERROR_NAMESPACE}search`

const SUCCESS_NAMESPACE = "massMailing:success."

export const CREATE_MASS_MAILING_SUCCESS_MESSAGE = `${SUCCESS_NAMESPACE}create`
export const START_MASS_MAILING_SUCCESS_MESSAGE = `${SUCCESS_NAMESPACE}start`
export const PAUSE_MASS_MAILING_SUCCESS_MESSAGE = `${SUCCESS_NAMESPACE}pause`
export const DELETE_MASS_MAILING_SUCCESS_MESSAGE = `${SUCCESS_NAMESPACE}delete`
export const ADD_MASS_MAILING_ITEMS_SUCCESS_MESSAGE = `${SUCCESS_NAMESPACE}add-items`

const PROCESS_NAMESPACE = "massMailing:process."

export const PROCESS_ADD_MASS_MAILING_MESSAGE = `${PROCESS_NAMESPACE}add-items`
