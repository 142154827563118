import { push } from "connected-react-router"
import { useCallback, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useGetMassMailingItemsByPageQuery, useGetMassMailingMutation } from "../../../../api/controllers/massMailing"
import FullscreenLoader from "../../../../components/FullscreenLoader/FullscreenLoader"
import Pagination from "../../../../components/Pagination/Pagination"
import AdvancedSkeleton from "../../../../components/Skeleton/AdvancedSkeleton"
import { selectGetChannelsNamesState } from "../../../../store/channels/selectors"
import { getChannelsNames } from "../../../../store/channels/thunks"
import { selectMassMailingById } from "../../../../store/massMailing/selectors"
import { selectCurrentProjectId } from "../../../../store/projects/selectors"
import { useAppDispatch, useAppSelector } from "../../../../store/hooks"
import { getMassMailingStatus } from "../../helpers"
import styles from "./MassMailingView.module.scss"
import { MassMailingItemsList } from "./components/MassMailingItemsList/MassMailingItemsList"
import { MassMailingViewHeader } from "./components/MassMailingViewHeader/MassMailingViewHeader"
import { MassMailingViewInfo } from "./components/MassMailingViewInfo/MassMailingViewInfo"
import { useMassMailingReports } from "./hooks/useMassMailingReports/useMassMailingReports"

const tNamespace = "massMailing:"
export const PAGE_SIZE = 100

export interface MassMailingViewProps {
    id: string
}

export const MassMailingView = ({ id }: MassMailingViewProps) => {
    const { t } = useTranslation()
    const dispatch = useAppDispatch()
    const [page, setPage] = useState(0)
    const [isItemsLoadAllowed, setIsItemsLoadAllowed] = useState(false)
    const mailing = useAppSelector(state => selectMassMailingById(state, id))
    const projectId = useAppSelector(selectCurrentProjectId)
    const { data: channelsNames } = useAppSelector(selectGetChannelsNamesState)
    const [getMailing, { isError, isLoading: isLoadingMailing }] = useGetMassMailingMutation()
    const {
        data: itemsData,
        isLoading,
        refetch
    } = useGetMassMailingItemsByPageQuery({
        ProjectId: projectId ?? "",
        Id: id,
        PageFrom: page,
        PageSize: PAGE_SIZE
    })
    const { openReportsSidebar, renderSidebar } = useMassMailingReports({ id })
    const loadItems = useCallback(() => {
        if (projectId) {
            refetch()
            setIsItemsLoadAllowed(false)
        }
    }, [id, projectId, page])

    useEffect(() => {
        if (projectId && !mailing) {
            getMailing({ ProjectId: projectId, Id: id })
        }
    }, [projectId, id, mailing])

    useEffect(() => setPage(0), [id])

    useEffect(() => {
        if (mailing?.Statistic) {
            setIsItemsLoadAllowed(true)
        }
    }, [mailing?.Statistic])

    useEffect(() => {
        if (projectId) {
            dispatch(getChannelsNames(projectId))
        }
    }, [projectId])

    if (isLoadingMailing) {
        return <FullscreenLoader />
    }

    if (!mailing) {
        // Error means not found
        if (isError) {
            dispatch(push(`/project/${projectId}/mass-mailing`))
        }
        return null
    }

    const status = getMassMailingStatus(mailing)
    const isDisplayPagination = isLoading || (itemsData && itemsData.Items.length > 0)
    const skeletonCount =
        mailing.Statistic.CountItems === 0 ? 10 : Math.min(101, mailing.Statistic.CountItems - page * PAGE_SIZE + 1)

    return (
        <>
            <div className={styles.view}>
                <MassMailingViewHeader
                    status={status}
                    mailing={mailing}
                    className={styles.view__header}
                    openReportsSidebar={openReportsSidebar}
                />
                <MassMailingViewInfo
                    mailing={mailing}
                    loadItems={loadItems}
                    className={styles.view__info}
                    isItemsLoadAllowed={isItemsLoadAllowed}
                />
                <>
                    {isLoading || (itemsData && itemsData.AddingInProgress) ? (
                        <div className={styles.view__skeleton}>
                            <AdvancedSkeleton
                                count={skeletonCount}
                                style={{ display: "flex" }}
                                className={styles.view__skeletonItem}
                                loadingText={
                                    itemsData && itemsData.AddingInProgress ? t(`${tNamespace}load-items`) : undefined
                                }
                            />
                        </div>
                    ) : itemsData && itemsData.Items.length > 0 ? (
                        <MassMailingItemsList
                            items={itemsData.Items}
                            omniChannelId={mailing.Data.SendingCascade?.Variants[0].ChannelIdForOmniUsers}
                            channelNames={channelsNames}
                            mailing={mailing}
                        />
                    ) : (
                        <div className={styles.view__noItems}>{t(`${tNamespace}no-items`)}</div>
                    )}
                    {isDisplayPagination && (
                        <div className={styles.view__pagination}>
                            <Pagination
                                total={mailing.Statistic.CountItems}
                                resultsPerPage={PAGE_SIZE}
                                onNextPage={() => setPage(page => page + 1)}
                                onPreviousPage={() => setPage(page => page - 1)}
                                pageIndex={page}
                                canPreviousPage={page > 0}
                                canNextPage={(page + 1) * PAGE_SIZE < mailing.Statistic.CountItems}
                            />
                        </div>
                    )}
                </>
            </div>
            <>{renderSidebar()}</>
        </>
    )
}
