import { MassMailingEventData, MassMailingItemsEvent } from "../../models/massMailing"
import { massMailingActions } from "../../store/massMailing/slice"
import { Store } from "../../store/store"
import { logHubError } from "../../utility/common/logError"
import { Dispatch } from "../../utility/common/storeHelper"
import { Hub } from "../hub"
import { IHub } from "../interfaces/IHub"
import { handleHttpExceptionWithoutAction } from "../../store/handleHttpException"
import {
    ADD_MASS_MAILING_ITEMS_FAILED_MESSAGE,
    ADD_MASS_MAILING_ITEMS_SUCCESS_MESSAGE
} from "../../store/massMailing/constants"
import { saveNotificationSuccess } from "../../store/notifications/thunks"
import i18next from "i18next"
import { massMailingController } from "../controllers/massMailing"

const HUB_NAME = "MassMailingHub"
const SEND_EVENT = "SendEvent"
const SEND_ITEMS_EVENT = "SendItemsEvent"

export class MassMailingHub {
    private _hub: IHub

    constructor(store: Store) {
        const reduxState = store.getState()
        const useAllTransportSignalR =
            reduxState.config.config.data?.WebConfig.appSettings.useAllTransportSignalR === true

        this._hub = new Hub(`/mass-sending-hub`, useAllTransportSignalR)

        this.registerServerEvents(store.dispatch)
    }

    async subscribe(projectId: string) {
        await this._hub.subscribe("Subscribe", projectId)
    }

    async unsubscribe(projectId: string) {
        await this._hub.unsubscribe("Unsubscribe", projectId)
    }

    private registerServerEvents(dispatch: Dispatch) {
        this._hub.registerEvent(SEND_EVENT, data => {
            try {
                const { MassSendingJob } = data as MassMailingEventData
                dispatch(massMailingActions.setMassMailings([MassSendingJob]))
            } catch (e) {
                logHubError(HUB_NAME, SEND_EVENT, e)
            }
        })

        this._hub.registerEvent(SEND_ITEMS_EVENT, async data => {
            try {
                const { ProjectId, Event, MassSendingJobId } = data as MassMailingItemsEvent

                if (Event.ValidateWarnings && Event.ValidateWarnings.length > 0) {
                    const errors = Event.ValidateWarnings.map(warn => warn.message).join("\n")
                    handleHttpExceptionWithoutAction(errors, ADD_MASS_MAILING_ITEMS_FAILED_MESSAGE, dispatch)
                }

                if (!(ProjectId && MassSendingJobId)) {
                    throw new Error(`ProjectId or MassSendingJobId is not defined`)
                }

                if (Event.CreatedCount > 0) {
                    saveNotificationSuccess(dispatch, {
                        Title: {
                            Value: i18next.t(ADD_MASS_MAILING_ITEMS_SUCCESS_MESSAGE, {
                                successCount: Event.CreatedCount
                            }),
                            NeedLocalization: false
                        }
                    })

                    await dispatch(
                        massMailingController.endpoints.getMassMailingItemsByPage.initiate(
                            {
                                ProjectId: ProjectId,
                                Id: MassSendingJobId,
                                PageFrom: 0,
                                PageSize: 100
                            },
                            {
                                forceRefetch: true
                            }
                        )
                    )

                    dispatch(
                        massMailingActions.updateMalling({
                            id: MassSendingJobId,
                            changes: {
                                Statistic: {
                                    CountItems: Event.CreatedCount,
                                    ErrorItems: Event.InputCount - Event.CreatedCount,
                                    SentItems: 0,
                                    DeliveredItems: 0
                                }
                            }
                        })
                    )
                }
            } catch (e) {
                logHubError(HUB_NAME, SEND_EVENT, e)
            }
        })
    }
}
