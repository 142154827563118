import { createEntityAdapter, createSlice, PayloadAction } from "@reduxjs/toolkit"
import { resetReducerState } from "../action"
import { SendingJob } from "./../../models/massMailing"

export const massMailingAdapter = createEntityAdapter<SendingJob>({
    selectId: massMailing => massMailing.Id
})

const initialState = massMailingAdapter.getInitialState()

const massMailingSlice = createSlice({
    name: "massMailing",
    initialState,
    reducers: {
        setMassMailings(state, action: PayloadAction<SendingJob[]>) {
            massMailingAdapter.setMany(state, action.payload)
        },
        updateMalling: massMailingAdapter.updateOne
    },
    extraReducers(builder) {
        builder.addCase(resetReducerState, () => {
            return initialState
        })
    }
})

export const MassMailingReducer = massMailingSlice.reducer

export const massMailingActions = massMailingSlice.actions
