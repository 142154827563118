import { SerializedError } from "@reduxjs/toolkit"
import { FetchBaseQueryError } from "@reduxjs/toolkit/dist/query"
import { TFunction } from "react-i18next"
import { massMailingController } from "../../api/controllers/massMailing"
import { SendingJob, SendingJobData } from "../../models/massMailing"
import { Dispatch } from "../../utility/common/storeHelper"
import { handleHttpExceptionWithoutAction } from "../handleHttpException"
import { saveNotificationSuccess } from "../notifications/thunks"
import {
    ADD_MASS_MAILING_ITEMS_FAILED_MESSAGE,
    CREATE_MASS_MAILING_FAILED_MESSAGE,
    CREATE_MASS_MAILING_SUCCESS_MESSAGE,
    DELETE_MASS_MAILING_FAILED_MESSAGE,
    DELETE_MASS_MAILING_SUCCESS_MESSAGE,
    PAUSE_MASS_MAILING_FAILED_MESSAGE,
    PAUSE_MASS_MAILING_SUCCESS_MESSAGE,
    PROCESS_ADD_MASS_MAILING_MESSAGE,
    START_MASS_MAILING_FAILED_MESSAGE,
    START_MASS_MAILING_SUCCESS_MESSAGE
} from "./constants"

const notifyUser = (
    data:
        | {
              data: unknown
          }
        | {
              error: FetchBaseQueryError | SerializedError
          },
    dispatch: Dispatch,
    successMassage: string,
    failedMessage: string
) => {
    if ("data" in data) {
        saveNotificationSuccess(dispatch, {
            Title: {
                Value: successMassage,
                NeedLocalization: true
            }
        })
    } else if ("error" in data) {
        handleHttpExceptionWithoutAction(data.error, failedMessage, dispatch)
    }
}

export const createMassMailing =
    (projectId: string, data: SendingJobData, csvLink: string, t: TFunction, callback?: (job: SendingJob) => void) =>
    async (dispatch: Dispatch) => {
        const createResult = await dispatch(
            massMailingController.endpoints.createMassMailing.initiate({
                ProjectId: projectId,
                Data: data
            })
        )

        if ("data" in createResult) {
            const massMailing = createResult.data
            const addResult = await dispatch(
                massMailingController.endpoints.addMassMailingItems.initiate({
                    ProjectId: projectId,
                    Id: massMailing.Id,
                    CsvLink: csvLink,
                    EmptyResponse: true
                })
            )

            if ("error" in addResult) {
                handleHttpExceptionWithoutAction(addResult.error, ADD_MASS_MAILING_ITEMS_FAILED_MESSAGE, dispatch)
            } else {
                saveNotificationSuccess(dispatch, {
                    Title: {
                        Value: t(PROCESS_ADD_MASS_MAILING_MESSAGE),
                        NeedLocalization: false
                    }
                })
            }

            callback && callback(massMailing)
        }
        notifyUser(createResult, dispatch, CREATE_MASS_MAILING_SUCCESS_MESSAGE, CREATE_MASS_MAILING_FAILED_MESSAGE)
    }

export const deleteMassMailing =
    ({ ProjectId, Id }: SendingJob) =>
    async (dispatch: Dispatch) => {
        const cancelResult = await dispatch(
            massMailingController.endpoints.deleteMassMailing.initiate({
                ProjectId,
                Id
            })
        )

        notifyUser(cancelResult, dispatch, DELETE_MASS_MAILING_SUCCESS_MESSAGE, DELETE_MASS_MAILING_FAILED_MESSAGE)
    }

export const startMassMailing = (sendingJob: SendingJob) => async (dispatch: Dispatch) => {
    const startResult = await dispatch(
        massMailingController.endpoints.updateMassMailing.initiate({
            ...sendingJob,
            Data: {
                ...sendingJob.Data,
                RunMode: 1
            }
        })
    )

    notifyUser(startResult, dispatch, START_MASS_MAILING_SUCCESS_MESSAGE, START_MASS_MAILING_FAILED_MESSAGE)
}

export const pauseMassMailing = (sendingJob: SendingJob) => async (dispatch: Dispatch) => {
    const pauseResult = await dispatch(
        massMailingController.endpoints.updateMassMailing.initiate({
            ...sendingJob,
            Data: {
                ...sendingJob.Data,
                RunMode: 0
            }
        })
    )

    notifyUser(pauseResult, dispatch, PAUSE_MASS_MAILING_SUCCESS_MESSAGE, PAUSE_MASS_MAILING_FAILED_MESSAGE)
}
